import React, { useContext, useState, useEffect, useRef } from 'react';
import { Link } from 'react-router-dom';
import { useNavigate } from 'react-router-dom';
import { AuthContext } from '../../authContext/authContext';
import { FaUserCircle } from 'react-icons/fa';
import './Navbar.css';

const Navbar = () => {
  const { authData, setAuthData } = useContext(AuthContext);
  const navigate = useNavigate();
  const [isDropdownOpen, setIsDropdownOpen] = useState(false);
  const dropdownRef = useRef(null); // Reference to the dropdown

  let logoClass = '';
  if (authData) {
    logoClass = authData.plan === 'Silver' ? 'silver-shadow' : 'gold-shadow';
  }
  useEffect(() => {
    const handleClickOutside = (event) => {
      if (dropdownRef.current && !dropdownRef.current.contains(event.target)) {
        setIsDropdownOpen(false);
      }
    };

    document.addEventListener('mousedown', handleClickOutside);
    return () => {
      document.removeEventListener('mousedown', handleClickOutside);
    };
  }, []);
  const toggleDropdown = () => {
    setIsDropdownOpen(!isDropdownOpen);
  };

  const logout = () => {
    localStorage.removeItem('authToken');
    setAuthData(null);
    navigate('/login');
  };

  return (
    <nav className="navbar">
      <div className="navbar-container">
        <div className="header-left">
          <a href="/" className='navbar-logo'>
            <div className={`logo ${logoClass}`}>
              <img src={process.env.PUBLIC_URL + '/logo_black.jpg'} alt="Logo" className="navbar-logo" />
            </div>
          </a>
        </div>
        <div className="header-right">
          <ul className="navLinks">
            {authData && (
              <>
                <li className='navLink'>
                  <Link to="/dashboard" className="navLink">Dashboard</Link>
                </li>
                <li className='navLink'>
                  <Link to="/strategy" className="navLink">Strategy</Link>
                </li>
              </>
            )}
            <li className='navLink'>
              <Link to="/contact-us" className="navLink">Contact Us</Link>
            </li>

            <li className='navLink'>
              <Link to="/products" className="navLink">Products</Link>
            </li>



            {!authData ? (
              <div>

                <li className='navLink'>
                  <Link to="/login" className="navLink">Login</Link>
                </li>
              </div>
            ) : (
              <>
                <li className='navLink'>
                  <Link to="/orders" className="navLink">Orders</Link>
                </li>
                <li className='navLink'>
                  <Link to="/referrals" className="navLink">Referrals</Link>
                </li>
                <li className="profile-icon" ref={dropdownRef}>
                  <div onClick={toggleDropdown} className="menu-button">
                    <FaUserCircle size={24} />
                  </div>
                  {isDropdownOpen && (
                    <div className="dropdown open">
                      <Link to="/profile" className="dropdown-item-navbar">View Profile</Link>
                      <div onClick={logout} className="dropdown-item-navbar">Logout</div>
                    </div>
                  )}
                </li>
              </>
            )}
          </ul>
        </div>
      </div>
    </nav>
  );
};

export default Navbar;
