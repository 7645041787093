import React from 'react';
import './ContactUs.css';
import { FaWhatsapp, FaEnvelope } from 'react-icons/fa'; // Importing WhatsApp and Email icons from react-icons

const ContactUs = () => {
    return (
        <div className="contact-container">
            <h1 className="contact-title">Contact Us</h1>
            <div className="contact-info">
                <p className="contact-name">Admin</p>
                <p className="contact-description">We’d love to hear from you! Reach out to us via WhatsApp or email for inquiries.</p>
                {/* WhatsApp link */}
                <a href="https://wa.me/+918076100935" className="contact-link" target="_blank" rel="noopener noreferrer">
                    <FaWhatsapp className="contact-icon" />
                    WhatsApp
                </a>
                {/* Email link */}
                <a href="mailto:etfkidukaan@gmail.com" className="contact-link" target="_blank" rel="noopener noreferrer">
                    <FaEnvelope className="contact-icon" />
                    Email: etfkidukaan@gmail.com
                </a>
            </div>
        </div>
    );
};

export default ContactUs;
