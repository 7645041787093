import React, { useEffect, useState } from 'react';
import axios from 'axios';
import './AdminPaymentsPage.css';

const AdminPaymentsPage = () => {
  const [payments, setPayments] = useState([]);
  const [filterUnverified, setFilterUnverified] = useState(false);

  // Fetch all payments from the backend when the component mounts
  useEffect(() => {
    const fetchPayments = async () => {
      try {
        const backendUrl = process.env.REACT_APP_NODE_BACKEND_URL;
        const response = await axios.get(`${backendUrl}/api/payments`); // Adjust the API endpoint if necessary
        setPayments(response.data.payments);
      } catch (error) {
        console.error('Error fetching payments:', error);
      }
    };

    fetchPayments();
  }, []);


  const handleVerifyPayment = async (paymentId) => {
    try {
      const backendUrl = process.env.REACT_APP_NODE_BACKEND_URL;
      await axios.put(`${backendUrl}/api/payments/verify/${paymentId}`);
      setPayments((prevPayments) =>
        prevPayments.map((payment) =>
          payment._id === paymentId ? { ...payment, status: 'accepted' } : payment
        )
      );
    } catch (error) {
      console.error('Error verifying payment:', error);
    }
  };

  // Function to reject a payment (set status to 'rejected')
  const handleRejectPayment = async (paymentId) => {
    try {
      const backendUrl = process.env.REACT_APP_NODE_BACKEND_URL;
      await axios.put(`${backendUrl}/api/payments/reject/${paymentId}`);
      setPayments((prevPayments) =>
        prevPayments.map((payment) =>
          payment._id === paymentId ? { ...payment, status: 'rejected' } : payment
        )
      );
    } catch (error) {
      console.error('Error rejecting payment:', error);
    }
  };

  // Toggle the filter for unverified payments
  const toggleFilterUnverified = () => {
    setFilterUnverified(!filterUnverified);
  };

  // Filter payments if the filter is applied
  const filteredPayments = filterUnverified
    ? payments.filter((payment) => !payment.verifiedPayment)
    : payments;

  return (
    <div className="admin-payments-container">
      <h1>Admin Payments</h1>
      <button className="filter-button" onClick={toggleFilterUnverified}>
        {filterUnverified ? 'Show All Payments' : 'Show Only Unverified Payments'}
      </button>

      <table className="payments-table">
        <thead>
          <tr>
            <th>User</th>
            <th>Email</th>
            <th>Tier</th>
            <th>Amount Paid</th>
            <th>Ref. Number</th>
            {/* <th>Days Remaining</th> */}
            <th>Verified</th>
            <th>Status</th>
            <th>Actions</th>
          </tr>
        </thead>
        <tbody>
          {filteredPayments.length > 0 ? (
            filteredPayments.map((payment) => (
              <tr key={payment._id}>
                <td>{payment.username}</td>
                <td>{payment.email}</td>
                <td>{payment.tier}</td>
                <td>{payment.amountPaid}</td>
                <td>{payment.refNumber}</td>
                {/* <td>{payment.daysRemaining}</td> */}
                <td>{payment.verifiedPayment ? 'Yes' : 'No'}</td>
                <td>
                  <span>{payment.status}</span> {/* Show payment status */}
                </td>
                <td>
                  <button
                    className="verify-button"
                    onClick={() => handleVerifyPayment(payment._id)}
                    disabled={payment.status !== 'pending'} // Only enable for pending payments
                  >
                    Accept
                  </button>
                  <button
                    className="reject-button"
                    onClick={() => handleRejectPayment(payment._id)}
                    disabled={payment.status !== 'pending'} // Only enable for pending payments
                  >
                    Reject
                  </button>
                </td>
              </tr>
            ))
          ) : (
            <tr>
              <td colSpan="8">No payments found</td>
            </tr>
          )}
        </tbody>
      </table>
    </div>
  );
};

export default AdminPaymentsPage;
