import React, { useContext, useEffect, useState } from 'react';
import axios from 'axios';
import { AuthContext } from '../../authContext/authContext';
import './PortfolioPage.css';
import ETFDetailsOverlay from '../ETFDetailsOverlay/ETFDetailsOverlay';
// import DummyPortfolio from '../DummyPortfolio'; // Import DummyPortfolio for Silver plan users
import DummyPortfolio from '../DummyPortfolio/DummyPortfolio'

const PortfolioPage = () => {
  const { authData } = useContext(AuthContext);
  const [portfolio, setPortfolio] = useState([]);
  const [loading, setLoading] = useState(true);
  const [overlayVisible, setOverlayVisible] = useState(false);
  const [selectedSymbol, setSelectedSymbol] = useState(null);
  const [sortConfig, setSortConfig] = useState({ key: null, direction: 'ascending' });
  const python_backend = process.env.REACT_APP_PYTHON_BACKEND_URL;

  useEffect(() => {
    if (authData && authData.plan === 'Gold') { // Only fetch portfolio if plan is Gold
      const fetchPortfolio = async () => {
        const token = localStorage.getItem('authToken');
        try {
          const response = await axios.post(
            `${python_backend}/api/fetch-portfolio`,
            { email: authData?.email },
            { headers: { Authorization: `Bearer ${token}` } }
          );
          if (response.data.success) {
            setPortfolio(response.data.portfolio);
          } else {
            console.error(response.data.message);
          }
        } catch (error) {
          console.error('Error fetching portfolio:', error);
        } finally {
          setLoading(false);
        }
      };
      fetchPortfolio();
    } else {
      setLoading(false); // Stop loading for Silver plan users as no portfolio data is fetched
    }
  }, [authData, python_backend]);

  const handleBuySellClick = (symbol) => {
    setSelectedSymbol(symbol.replace('-EQ', ''));
    setOverlayVisible(true);
  };

  const closeOverlay = () => {
    setOverlayVisible(false);
    setSelectedSymbol(null);
  };

  const sortPortfolio = (key) => {
    let direction = 'ascending';
    if (sortConfig.key === key && sortConfig.direction === 'ascending') {
      direction = 'descending';
    }
    setSortConfig({ key, direction });
    setPortfolio((prevPortfolio) =>
      [...prevPortfolio].sort((a, b) => {
        if (a[key] < b[key]) return direction === 'ascending' ? -1 : 1;
        if (a[key] > b[key]) return direction === 'ascending' ? 1 : -1;
        return 0;
      })
    );
  };

  const getSortIcon = (key) => {
    if (sortConfig.key === key) {
      return sortConfig.direction === 'ascending' ? '▲' : '▼';
    }
    return '';
  };

  // Render different components based on the user's plan
  if (authData.plan === 'Silver') {
    return <DummyPortfolio />; // Render DummyPortfolio for Silver plan users
  }

  return (
    <div className="portfolio-container">
      <h2>Your Portfolio</h2>
      {loading ? (
        <p>Loading your portfolio...</p>
      ) : portfolio.length > 0 ? (
        <table className="portfolio-table">
          <thead>
            <tr>
              <th onClick={() => sortPortfolio('symbol')}>Symbol {getSortIcon('symbol')}</th>
              <th onClick={() => sortPortfolio('number_of_shares')}>Qty {getSortIcon('number_of_shares')}</th>
              <th onClick={() => sortPortfolio('avg_amount_per_share')}>Buy Price (₹) {getSortIcon('avg_amount_per_share')}</th>
              <th onClick={() => sortPortfolio('current_value_per_share')}>CMP (₹) {getSortIcon('current_value_per_share')}</th>
              <th onClick={() => sortPortfolio('amount_invested')}>Amount Invested (₹) {getSortIcon('amount_invested')}</th>
              <th onClick={() => sortPortfolio('current_amount')}>Current Amount (₹) {getSortIcon('current_amount')}</th>
              <th onClick={() => sortPortfolio('gain')}>Gain (₹) {getSortIcon('gain')}</th>
              <th onClick={() => sortPortfolio('gain_percentage')}>Gain (%) {getSortIcon('gain_percentage')}</th>
              <th>Buy/Sell</th>
            </tr>
          </thead>
          <tbody>
            {portfolio.map((holding, index) => (
              <tr key={index}>
                <td>{holding.symbol.replace('-EQ', '')}</td>
                <td>{holding.number_of_shares}</td>
                <td>{holding.avg_amount_per_share?.toFixed(2) || 'N/A'}</td>
                <td>{holding.current_value_per_share?.toFixed(2) || 'N/A'}</td>
                <td>{holding.amount_invested?.toFixed(2) || 'N/A'}</td>
                <td>{holding.current_amount?.toFixed(2) || 'N/A'}</td>
                <td className={holding.gain >= 0 ? 'gain' : 'loss'}>{holding.gain?.toFixed(2) || 'N/A'}</td>
                <td className={holding.gain_percentage >= 0 ? 'gain' : 'loss'}>{holding.gain_percentage?.toFixed(2) || 'N/A'}</td>
                <td>
                  <button onClick={() => handleBuySellClick(holding.symbol)} className="buy-sell-button">
                    Buy/Sell
                  </button>
                </td>
              </tr>
            ))}
          </tbody>
        </table>
      ) : (
        <p>No holdings found.</p>
      )}

      {overlayVisible && (
        <ETFDetailsOverlay symbol={selectedSymbol} onClose={closeOverlay} />
      )}
    </div>
  );
};

export default PortfolioPage;
