import React, { useState, useEffect, useContext, useRef } from 'react';
import axios from 'axios';
import { AuthContext } from '../../authContext/authContext';
import './ETFDetailsOverlay.css';

const ETFDetailsOverlay = ({ symbol, onClose }) => {
  const [etf, setEtf] = useState(null);
  const [orderType, setOrderType] = useState('buy');
  const [orderMode, setOrderMode] = useState('market'); // market or limit
  const [quantity, setQuantity] = useState('');
  const [orderPrice, setOrderPrice] = useState(''); // Used for limit orders
  const [showConfirm, setShowConfirm] = useState(false);
  const [orderDetails, setOrderDetails] = useState({});
  const node_backend = process.env.REACT_APP_NODE_BACKEND_URL;
  const python_backend = process.env.REACT_APP_PYTHON_BACKEND_URL;

  const { authData } = useContext(AuthContext);
  const formRef = useRef(null);

  useEffect(() => {
    const fetchETFDetails = async () => {
      try {
        const response = await axios.get(`${node_backend}/api/etf/etfs/${symbol}/details`);
        setEtf(response.data);
      } catch (error) {
        console.error("Error fetching the ETF details!", error);
      }
    };

    fetchETFDetails();
  }, [node_backend, symbol]);

  const handleOrder = () => {
    const price = orderMode === 'limit' ? parseFloat(orderPrice) : etf.price;

    if (authData && authData.email && quantity > 0 && price > 0) {
      const totalPrice = price * quantity;
      setOrderDetails({
        symbol,
        orderType,
        quantity,
        price,
        totalPrice,
      });
      setShowConfirm(true);
    } else {
      alert("Please provide valid quantity and price for your order.");
    }
  };

  const confirmOrder = async () => {
    setShowConfirm(false);
    try {
      await axios.post(`${python_backend}/api/orders/place`, {
        email: authData.email,
        symbol,
        orderType,
        quantity,
        price: orderDetails.price,
      });
      alert(`${orderType.toUpperCase()} order placed for ${quantity} shares of ${symbol}`);
      window.location.reload();
    } catch (error) {
      console.error(`Error placing the ${orderType} order!`, error);
      alert(`Failed to place ${orderType} order. Please try again.`);
    }
  };

  const handleQuantityChange = (e) => {
    const value = e.target.value;
    if (!isNaN(value) && Number(value) >= 0) {
      setQuantity(value);
    }
  };

  const handlePriceChange = (e) => {
    const value = e.target.value;
    if (!isNaN(value) && Number(value) >= 0) {
      setOrderPrice(value);
    }
  };

  if (!etf) {
    return <div className="loading">Loading ETF details...</div>;
  }

  return (
    <div className="etf-details-overlay">
      <div className="overlay-content">
        <button className="close-btn" onClick={onClose}>Close</button>
        <h1>{etf.symbol} Details</h1>
        <p>Price: ₹{etf.price}</p>
        <p>Change: {etf.change > 0 ? '+' : ''}{etf.change.toFixed(2)} ({etf.changePercent.toFixed(2)}%)</p>
        
        <div className="order-section">
          <label>Order Type:</label>
          <select value={orderType} onChange={(e) => setOrderType(e.target.value)}>
            <option value="buy">Buy</option>
            <option value="sell">Sell</option>
          </select>
          
          <label>Order Mode:</label>
          <select value={orderMode} onChange={(e) => setOrderMode(e.target.value)}>
            <option value="market">Market</option>
            <option value="limit">Limit</option>
          </select>

          {orderMode === 'limit' && (
            <div>
              <label>Limit Price:</label>
              <input
                type="number"
                value={orderPrice}
                onChange={handlePriceChange}
                min="0"
              />
            </div>
          )}

          <label>Quantity:</label>
          <input
            type="number"
            value={quantity}
            onChange={handleQuantityChange}
            min="0"
          />

          <button
            onClick={handleOrder}
            disabled={!authData.creds}
            className={`${orderType}-btn ${!authData.creds ? 'disabled-button' : ''}`}
          >
            {orderType.toUpperCase()} ETF
          </button>

          {!authData.creds && (
            <p className="message">
              Please <a href="/profile" className="profile-link">go to your profile</a> to submit your credentials before buying or selling ETFs.
            </p>
          )}
        </div>

        {showConfirm && (
          <div className="confirmation-dialog">
            <h2>Confirm Order</h2>
            <p><strong>Order Type:</strong> {orderDetails.orderType.toUpperCase()}</p>
            <p><strong>Order Mode:</strong> {orderMode.toUpperCase()}</p>
            <p><strong>Symbol:</strong> {orderDetails.symbol}</p>
            <p><strong>Quantity:</strong> {orderDetails.quantity}</p>
            <p><strong>Price:</strong> ₹{orderDetails.price.toFixed(2)}</p>
            <p><strong>Total Price:</strong> ₹{orderDetails.totalPrice.toFixed(2)}</p>
            <button onClick={confirmOrder} className="confirm-btn">Confirm Order</button>
            <button onClick={() => setShowConfirm(false)} className="cancel-btn">Cancel</button>
          </div>
        )}
      </div>
    </div>
  );
};

export default ETFDetailsOverlay;
