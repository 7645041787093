import React, { useRef, useState, useContext } from 'react';
import axios from 'axios';
import './ProductPage.css';
import { useNavigate } from 'react-router-dom';
import { AuthContext } from '../../authContext/authContext';

const Products = () => {
  const [isYearly, setIsYearly] = useState(false);

  const [showPaymentForm, setShowPaymentForm] = useState({ tier: '', visible: false });
  const navigate = useNavigate();
  const { authData } = useContext(AuthContext); // Access auth context for user data
  const togglePricing = () => {
    setIsYearly(!isYearly);
  };
  const handleGetStartedClick = () => {
    // Check if the user is logged in
    if (!authData) {
      // If not logged in, alert the user and redirect to the login page
      // alert('Please log in to make a purchase.');
      navigate('/login'); // Redirect to login page
      return;
    }
    else {
      navigate('/strategy');
      return;
    }

    // If logged in, show the payment form
  };
  // Function to handle the "Purchase Now" button click
  const handlePurchaseClick = (tier) => {
    // Check if the user is logged in
    if (!authData) {
      // If not logged in, alert the user and redirect to the login page
      alert('Please log in to make a purchase.');
      navigate('/login'); // Redirect to login page
      return;
    }

    // If logged in, show the payment form
    setShowPaymentForm({ tier, visible: true });
  };

  // Handle form submission for payment
  const handlePaymentSubmit = async (e) => {
    e.preventDefault();
    const name = e.target.name.value;
    const amountPaid = parseFloat(e.target.amountPaid.value); // Ensure the amount is a number
    const refNumber = e.target.refNumber.value;
    const backendUrl = process.env.REACT_APP_NODE_BACKEND_URL;

    try {
      const response = await axios.post(`${backendUrl}/api/payments/payment`, {
        tier: showPaymentForm.tier,
        name,
        amountPaid,
        refNumber,
        userId: authData._id,  // Pass the logged-in user's ID
        username: authData.username,  // Pass the logged-in user's username
        email: authData.email,  // Pass the logged-in user's email
      });

      if (response.data.success) {
        alert('Form Submitted. Our team will activate your account after payment verification!');
        setShowPaymentForm({ tier: '', visible: false });
        navigate("/strategy"); // Navigate after successful payment
      } else {
        alert('Payment failed, please try again.');
      }
    } catch (error) {
      console.error('Payment submission error:', error);
      alert('An error occurred, please try again.');
    }
  };

  // Close the payment form popup
  const handleClosePopup = () => {
    setShowPaymentForm({ tier: '', visible: false });
  };

  return (
    <div className="products-page">
      <div class="banner">
        Avail upto 60% Early Bird discount till 31st Dec! Don't miss this chance!!
      </div>
      <div className="products-container">
        <header className="products-header">
          <h1>Our Products</h1>
          <p>Choose the plan that suits your trading style and goals</p>

          {/* Toggle switch for monthly/yearly pricing */}
          <div className="toggle-container">
            <span>Monthly</span>
            <label className="toggle-switch">
              <input type="checkbox" onChange={togglePricing} />
              <span className="slider"></span>
            </label>
            <span>Yearly</span>
          </div>
        </header>
        <section className="pricing-section" >
          <div className="pricing-card-container">
            {/* Free plan */}
            <div className="pricing-card">
              <div className='card-heading'>

              <h3>Free</h3>
              </div>

              <div className='price-card-desc'>Explore the website and retain full control over your trades.</div>
              <div className='features'>
                <h4>Core Features</h4>
                <ul>
                  <li className="checked">Tradeable ETFs</li>

                </ul>
                <h4>Limitations</h4>
                <ul>
                  <li className="unchecked">Get Updates about ETF Prices</li>
                  <li className="unchecked">Buying Recommendations</li>
                  <li className="unchecked">Buy/Sell from the Website</li>
                  <li className="unchecked">Selling Recommendations</li>
                  <li className="unchecked">Fully Automated</li>              </ul>
              </div>
              <p className="price">
                Free
              </p>
              <button className="purchase-button" onClick={() => handleGetStartedClick()}>Get Started</button>

            </div>

            {/* Silver plan */}
            <div className="pricing-card">
              <div className='card-heading'>

              <h3>Silver</h3>
              </div>
              <div className='price-card-desc'>Get daily recommendations and execute trades manually with confidence.</div>
              <div className='features'>

                <h4>Core Features</h4>
                <ul>
                  <li className="checked">Tradeable ETFs</li>
                  <li className="checked">Get Updates about ETF Prices</li>
                  <li className="checked">Buying Recommendations</li>
                  <li className="checked">Selling Recommendations</li>
                  <li className="checked">Buy/Sell from the Website</li>
                </ul>
                <h4>Limitations</h4>
                <ul>
                  <li className="unchecked">Fully Automated</li>
                </ul>
              </div>
              <p className="price">

                {/* Display pricing based on toggle */}
                <p className="original-price">
                  {isYearly ? (
                    <>
                      <s>₹5999/Year</s> <span className="discounted-price">₹1999/Year</span>
                    </>
                  ) : (
                    <>
                      <s>₹499/Month</s> <span className="discounted-price">₹199/Month</span>
                    </>
                  )}
                </p>
              </p> <button className="purchase-button" onClick={() => handlePurchaseClick('Silver')}>Purchase Now</button>
            </div>

            {/* Gold plan */}
            <div className="pricing-card">
            <div className='card-heading'>

              <h3>Gold</h3>
              </div>
              <div className='price-card-desc'>Fully automated trading for a hands-free experience.</div>
              <div className='features'>

                <h4>Core Features</h4>

                <ul>
                  <li className="checked">Tradeable ETFs</li>
                  <li className="checked">Get Updates about ETF Prices</li>
                  <li className="checked">Buying Recommendations</li>
                  <li className="checked">Selling Recommendations</li>
                  <li className="checked">Buy/Sell from the Website</li>
                  <li className="checked">Fully Automated</li>
                </ul>
              </div>
              <p className="price">

                {/* Display pricing based on toggle */}
                <p className="original-price">
                  {isYearly ? (
                    <>
                      <s>₹7999/Year</s> <span className="discounted-price">₹2999/Year</span>
                    </>
                  ) : (
                    <>
                      <s>₹699/Month</s> <span className="discounted-price">₹299/Month</span>
                    </>
                  )}
                </p>
              </p>
              <button className="purchase-button" onClick={() => handlePurchaseClick('Gold')}>Purchase Now</button>
            </div>
          </div>

          {/* Payment form popup */}
          {showPaymentForm.visible && (
            <div className="overlay">
              <div className="popup">
                <button className="close-button" onClick={handleClosePopup}>&times;</button>
                <form className="payment-form" onSubmit={handlePaymentSubmit}>
                  <h3>Complete Your Purchase for {showPaymentForm.tier} Pack</h3>
                  <img src={process.env.PUBLIC_URL + '/qr.jpeg'} alt="QR Code" className="qr-code" />
                  <input type="text" name="name" placeholder="Name" required />
                  <input type="number" name="amountPaid" placeholder="Amount Paid" required />
                  <input type="text" name="refNumber" placeholder="Last 4 Digits of Ref. Number" required />
                  <button type="submit" className="submit-payment">Submit</button>
                </form>
              </div>
            </div>
          )}
        </section>
      </div>
    </div>
  );
};

export default Products;
