import React, { useState, useContext, useEffect } from 'react';
import axios from 'axios';
import { AuthContext } from '../../authContext/authContext';
import './StrategyPage.css';
import ETFDetailsOverlay from '../ETFDetailsOverlay/ETFDetailsOverlay';
import ReactFlow, { MiniMap, Controls, Background, ReactFlowProvider } from 'react-flow-renderer';
import { useParams, Link } from 'react-router-dom';
import BacktestedResults from '../BacktestedResults/BacktestedResults';

const StrategyPage = () => {
  const { authData } = useContext(AuthContext);
  const [strategies, setStrategies] = useState([{ name: 'ETF Trading Strategy', percentage: '100%', mode: 'manual' }]);
  const node_backend = process.env.REACT_APP_NODE_BACKEND_URL;
  const python_backend = process.env.REACT_APP_PYTHON_BACKEND_URL;
  const python_backend_server = process.env.REACT_APP_PYTHON_BACKEND_URL_SERVER;
  const [recommendation, setRecommendation] = useState('');
  const [showUpgradeMessage, setShowUpgradeMessage] = useState(false);
  const [showCredsForm, setShowCredsForm] = useState(false);
  const [creds, setCreds] = useState({ token: '', uid: '', pwd: '', vc: '', api_key: '', imei: '' });
  const [etfRecommendations, setEtfRecommendations] = useState([]);
  const [showInstructions, setShowInstructions] = useState(false);
  const [showBacktestedResults, setShowBacktestedResults] = useState(false); // State for backtested results
  const [dailyAmount, setDailyAmount] = useState(authData?.daily_amount ?? 0);
  const [errorMessage, setErrorMessage] = useState(''); // New state for error message
  const [overlayVisible, setOverlayVisible] = useState(false);
  const [selectedSymbol, setSelectedSymbol] = useState(null);

  const handleDailyAmountChange = (e) => {

    const value = e.target.value;


    const isValidNumber = /^[0-9]+(\.[0-9]+)?$/.test(value);

    setDailyAmount(value);



  };
  const handleNodesChange = () => { };
  const handleEdgesChange = () => { };
  const handleConnect = () => { };
  const nodes = [
    { id: '1', type: 'input', data: { label: 'Start with most Fallen ETF (top)' }, position: { x: 250, y: 0 } },
    { id: '2', data: { label: 'Is it in your Portfolio?' }, position: { x: 250, y: 100 } },
    { id: '3', data: { label: 'Check if fallen > 2% from avg buying price' }, position: { x: 250, y: 200 } },
    { id: '4', data: { label: 'Go to the next most fallen ETF (one row down)' }, position: { x: 50, y: 300 } },
    { id: '5', data: { label: 'Buy it with amount equal to your (Capital + Current Portfolio)/40' }, position: { x: 550, y: 300 } },
    { id: '6', data: { label: 'Stop Buying' }, position: { x: 550, y: 400 } },
    { id: '7', data: { label: 'Check if any ETF has > 6% profit and Sell it' }, position: { x: 250, y: 400 } },
    // { id: '8', data: { label: 'Sell all ETFs with > 6% profit' }, position: { x: 250, y: 500 } },
  ];

  const edges = [
    { id: 'e1-2', source: '1', target: '2', animated: true },
    { id: 'e4-2', source: '4', target: '2', animated: true },
    { id: 'e2-3', source: '2', target: '3', label: 'Yes', animated: true },
    { id: 'e2-5', source: '2', target: '5', label: 'No', animated: true },
    { id: 'e3-4', source: '3', target: '4', label: 'No', animated: true },
    { id: 'e3-5', source: '3', target: '5', label: 'Yes', animated: true },
    { id: 'e5-6', source: '5', target: '6', animated: true },
    { id: 'e6-7', source: '6', target: '7', animated: true },
    { id: 'e7-8', source: '7', target: '8', animated: true },
    // { id: 'e7-5', source: '7', target: '5', label: 'No', animated: true },
  ];

  const Flow = ({ nodes, edges, onNodesChange, onEdgesChange, onConnect }) => {
    return (
      <ReactFlow
        nodes={nodes}
        edges={edges}
        onNodesChange={onNodesChange}
        onEdgesChange={onEdgesChange}
        onConnect={onConnect}
      >
        {/* <MiniMap nodeColor={() => '#007bff'} /> */}
        <Controls />
        <Background />
      </ReactFlow>
    );
  };
  useEffect(() => {
    if (authData && authData.plan !== 'Free' && !authData.creds) {
      setShowCredsForm(true);
    }
  }, [authData]);

  useEffect(() => {
    fetchEtfRecommendations();
  }, []);

  const fetchEtfRecommendations = async () => {
    try {
      const response = await axios.get(`${node_backend}/api/etf/etfs/top-fallen`);
      if (response.data.success) {
        console.log(response.data.data);
        setEtfRecommendations(response.data.data);
      } else {
        console.error('Failed to fetch ETF recommendations');
      }
    } catch (error) {
      console.error('Error fetching ETF recommendations:', error);
    }
  };
  const handleBacktestedResultsClick = () => {
    setShowBacktestedResults(true); // Show the overlay when the button is clicked
  };

  const closeBacktestedResults = () => {
    setShowBacktestedResults(false); // Close the overlay
  };
  const handleBuySellClick = (symbol) => {
    setSelectedSymbol(symbol.replace('-EQ', ''));
    setOverlayVisible(true);
  };

  const closeOverlay = () => {
    setOverlayVisible(false);
    setSelectedSymbol(null);
  };
  const handleToggleMode = async () => {
    const token = localStorage.getItem('authToken');
    if (token) {

      if (authData.plan === 'Silver') {
        setShowUpgradeMessage(true);
        return;
      }

      setShowUpgradeMessage(false);

      if (dailyAmount < 0 || !/^[0-9]+(\.[0-9]+)?$/.test(dailyAmount)) {
        setErrorMessage('Please enter a valid amount greater than or equal to 0');
        setDailyAmount(0); // Reset the dailyAmount to 0
        return;
      }
      const confirmationMessage = authData.automated
        ? 'Are you sure you want to switch to manual mode?'
        : 'Are you sure you want to switch to automatic mode?';

      if (!window.confirm(confirmationMessage)) {

        return;
      }

      try {


        const newAutomatedState = !authData.automated;
        const response = await axios.post(
          `${node_backend}/api/auth/toggle-mode`,
          {
            userId: authData._id,
            automated: newAutomatedState,
          },
          {
            headers: { Authorization: `Bearer ${token}` }  // Ensure Authorization header is set
          }
        );

        console.log('checking if toggles ');
        if (response.data.success) {
          console.log('yes toggles, sendign req to ');
          console.log(`${python_backend_server}/api/strategy/start`);
          const pythonEndpoint = newAutomatedState
            ? `${python_backend_server}/api/strategy/start`
            : `${python_backend_server}/api/strategy/stop`;

          // Send request to start or stop the strategy on the Python backend
          try {

            await axios.post(
              pythonEndpoint,
              {
                userId: authData._id,
                dailyAmount, // Send dailyAmount to the backend

              },
              {
                headers: { Authorization: `Bearer ${token}` }
              }
            );
            console.log('posted !! ')
          }
          catch {
            const newAutomatedState = authData.automated;
            const response = await axios.post(
              `${node_backend}/api/auth/toggle-mode`,
              {
                userId: authData._id,
                automated: newAutomatedState,
              },
              {
                headers: { Authorization: `Bearer ${token}` }  // Ensure Authorization header is set
              }
            );
            console.error('Failed to start / stop the strategy');
          }


          authData.automated = newAutomatedState;
        } else {

          throw new Error('Failed to update automated state');
        }
        window.location.reload();

      } catch (error) {
        try {

          const newAutomatedState = authData.automated;

          const response = await axios.post(`${node_backend}/api/auth/toggle-mode`, {
            userId: authData._id,
            automated: newAutomatedState,
            headers: { Authorization: `Bearer ${token}` }

          });

        }
        catch {
          console.error("node backend toggle mode error");
        }
        console.error('Error toggling mode:', error);
        alert('An error occurred while toggling the mode. Please try again.');
      }
    }
  };

  const handleRecommendation = () => {
    setRecommendation('Buy XYZ ETF');
  };




  if (!authData) {
    return <p>Loading...</p>;
  }

  if (!strategies) {
    return <p>Loading strategies...</p>;
  }

  return (
    <div className="strategy-container">
      {authData.plan === 'Free' ? (
        <div className="free-tier-message">
          <p>
            To use strategies, please visit our{' '}
            <a href="/products">Products Page</a> and subscribe to a Silver or Gold plan.
          </p>
        </div>
      ) : null}
      <div className="strategies-section">
        <header className="strategies-header">
          <h1>Manage Your Strategy</h1>
        </header>
        {authData.creds ? (
          <>
            {strategies.map((strategy) => (
              <div key={strategy.id} className="strategy-card">
                <div className="strategy-header">
                  <h2>{strategy.name}</h2>
                </div>
                <div className="strategy-body">
                  <div className="allocation">
                    <label>Allocate Funds (%): </label>
                    <input type="text" value={strategy.percentage} readOnly />
                  </div>
                  <label>
                    Daily Buying Amount (₹):
                    <input
                      type="number"
                      value={dailyAmount}
                      onChange={handleDailyAmountChange}
                      disabled={authData.automated || authData.plan === 'Free'}
                    />

                  </label>
                  {errorMessage && <p className="error-message">{errorMessage}</p>} {/* Display error message if any */}

                  <p>Note: If you set the value to <strong>0</strong>, the algorithm will automatically determine the optimal buying amount based on your strategy.</p>

                  {authData.automated && (
                    <p className="info-message">
                      Automation is currently enabled. If you'd like to manually enter the buying amount, you'll need to disable automation first. Simply click "Switch Mode" to toggle between Automatic and Manual mode.
                    </p>
                  )}
                  <div className="mode-toggle">
                    <label>Mode: </label>
                    <button
                      onClick={() => handleToggleMode(strategy.id)}
                      className={`toggle-btn ${authData.plan === 'Silver' ? 'disabled' : ''}`}
                      disabled={authData.plan === 'Free'}
                    >
                      Switch Mode
                    </button>
                    <p>Current Mode: {authData.automated ? 'Automatic' : 'Manual'}</p>
                  </div>
                </div>
              </div>
            ))}
            {showUpgradeMessage && <p className="upgrade-message">Upgrade to Gold plan to enable automatic mode.</p>}
            <div className='buttons-table-top'>

              <button
              onClick={handleRecommendation} 
              className="recommendation-btn" 
              disabled={authData.plan === 'Free'}
              >
                Get Recommendations
              </button>
              <button onClick={handleBacktestedResultsClick} 
              className="backtested-results-btn"
              disabled={authData.plan === 'Free'}
              >
                Show Backtested Results
              </button>
            </div>

            {showBacktestedResults && (
              <div className="backtested-results-overlay">
                <BacktestedResults onClose={closeBacktestedResults} />
              </div>
            )}
            {recommendation && (
              <div className="recommendation">
                <div className="etf-recommendations">
                  <div className="table-header">
                    <h2>Top Fallen ETFs</h2>
                    <button className="show-instructions-btn" onClick={() => setShowInstructions(true)}>
                      Show Instructions
                    </button>
                  </div>
                  <div className="etf-header">
                    <span className="header-symbol">Symbol</span>
                    <span className="header-dma20">20 DMA</span>
                    <span className="header-cmp">CMP</span>
                    <span className="header-percentage-change">Percentage Change</span>
                    <span className="header-details">Buy / Sell</span>
                  </div>
                  {etfRecommendations.map((etf, index) => (
                    <div key={index} className="etf-card">
                      <span className="etf-symbol">{etf.symbol}</span>
                      <span className="etf-dma20">{etf.dma20.toFixed(2)}</span>
                      <span className="etf-cmp">{etf.cmp.toFixed(2)}</span>
                      <span className="etf-percentage-change">{etf.percentage_change.toFixed(2)}%</span>
                      <span className='etf-details-link'>
                      <button onClick={() => handleBuySellClick(`${etf.symbol}`)} className="buy-sell-button">
                    Buy/Sell
                  </button>
                      </span>
                    </div>
                  ))}
                </div>

                {showInstructions && (
                  <div className="instructions-overlay">
                    <div className="instructions-content">
                      <button className="close-btn" onClick={() => setShowInstructions(false)}>
                        Close
                      </button>
                      <h3>Buying/Selling Instructions</h3>
                      <div style={{ height: 500 }}>
                        <ReactFlowProvider>
                          <Flow
                            nodes={nodes}
                            edges={edges}
                            onNodesChange={handleNodesChange}
                            onEdgesChange={handleEdgesChange}
                            onConnect={handleConnect}
                          />
                        </ReactFlowProvider>

                      </div>
                    </div>
                  </div>
                )}

              </div>
            )}
          </>
        ) : (
          <p className="message">
            Please <Link to="/profile" className="profile-link">go to your profile</Link> to submit your credentials before you can proceed with buying or selling ETFs.
          </p>)}

      </div>
      {overlayVisible && (
        <ETFDetailsOverlay symbol={selectedSymbol} onClose={closeOverlay} />
      )}
    </div>
  );
};

export default StrategyPage;
