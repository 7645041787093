import React, { useState, useEffect, useContext } from 'react';
import axios from 'axios';
import { AuthContext } from '../../authContext/authContext';
import './DummyPortfolio.css'; // Import the CSS file

const Portfolio = () => {
    const { authData } = useContext(AuthContext);
    const [etfs, setEtfs] = useState([]);
    const [portfolio, setPortfolio] = useState([]);
    const [symbol, setSymbol] = useState('');
    const [avgBuyPrice, setAvgBuyPrice] = useState('');
    const [quantity, setQuantity] = useState('');
    const node_backend = process.env.REACT_APP_NODE_BACKEND_URL;

    useEffect(() => {
        const fetchEtfs = async () => {
            try {
                const response = await axios.get(`${node_backend}/api/etf/etfs`);
                setEtfs(response.data);
            } catch (error) {
                console.error('Error fetching ETFs:', error);
            }
        };
        fetchEtfs();
    }, [node_backend]);

    useEffect(() => {
        if (authData && authData.email) {
            const fetchPortfolio = async () => {
                try {
                    const response = await axios.get(`${node_backend}/api/portfolio/view`, {
                        params: { email: authData.email },
                    });
                    const updatedPortfolio = response.data.map((entry) => {
                        const etf = etfs.find((etf) => etf.symbol === entry.symbol);
                        const currentPrice = etf ? etf.price : 0;
                        const netGainLoss = (currentPrice && entry.avgBuyPrice && entry.quantity)
                            ? (currentPrice - entry.avgBuyPrice) * entry.quantity
                            : 0;
                        const percentageGainLoss = (currentPrice && entry.avgBuyPrice)
                            ? ((currentPrice - entry.avgBuyPrice) / entry.avgBuyPrice) * 100
                            : 0;
                        return {
                            ...entry,
                            currentPrice,
                            netGainLoss,
                            percentageGainLoss,
                        };
                    });
                    setPortfolio(updatedPortfolio);
                } catch (error) {
                    console.error('Error fetching portfolio:', error);
                }
            };
            fetchPortfolio();
        }
    }, [authData, etfs, node_backend]);

    const addToPortfolio = async (e) => {
        e.preventDefault();
        if (authData && authData.email) {
            try {
                const newEntry = { symbol, avgBuyPrice: parseFloat(avgBuyPrice), quantity: parseInt(quantity) };
                const response = await axios.post(`${node_backend}/api/portfolio/add`, {
                    email: authData.email,
                    ...newEntry,
                });
                const entry = response.data;
                const etf = etfs.find((etf) => etf.symbol === entry.symbol);
                const currentPrice = etf ? etf.price : 0;
                const netGainLoss = (currentPrice && entry.avgBuyPrice && entry.quantity)
                    ? (currentPrice - entry.avgBuyPrice) * entry.quantity
                    : 0;
                const percentageGainLoss = (currentPrice && entry.avgBuyPrice)
                    ? ((currentPrice - entry.avgBuyPrice) / entry.avgBuyPrice) * 100
                    : 0;
                const updatedEntry = {
                    ...entry,
                    currentPrice,
                    netGainLoss,
                    percentageGainLoss,
                };
                setPortfolio((prevPortfolio) => [...prevPortfolio, updatedEntry]);
                setSymbol('');
                setAvgBuyPrice('');
                setQuantity('');
            } catch (error) {
                console.error('Error adding to portfolio:', error);
            }
        }
    };

    const deleteFromPortfolio = async (symbolToDelete) => {
        if (authData && authData.email) {
            try {
                await axios.post(`${node_backend}/api/portfolio/delete`, {
                    email: authData.email,
                    symbol: symbolToDelete,
                });
                setPortfolio(portfolio.filter((item) => item.symbol !== symbolToDelete));
            } catch (error) {
                console.error('Error deleting from portfolio:', error);
            }
        }
    };

    return (
        <div className="portfolio-wrapper">
            <form onSubmit={addToPortfolio} className="portfolio-form-section">
                <h3>Create Your Portfolio</h3>
                <select value={symbol} onChange={(e) => setSymbol(e.target.value)} required>
                    <option value="">Select Symbol</option>
                    {etfs.map((etf) => (
                        <option key={etf.symbol} value={etf.symbol}>
                            {etf.symbol} - ₹{etf.price}
                        </option>
                    ))}
                </select>
                <input
                    type="number"
                    value={avgBuyPrice}
                    onChange={(e) => setAvgBuyPrice(e.target.value)}
                    placeholder="Average Buy Price"
                    required
                />
                <input
                    type="number"
                    value={quantity}
                    onChange={(e) => setQuantity(e.target.value)}
                    placeholder="Quantity"
                    required
                />
                <button type="submit">Add to Portfolio</button>
            </form>

            <table className="portfolio-data-table">
                <thead>
                    <tr>
                        <th>Symbol</th>
                        <th>Average Buy Price</th>
                        <th>Quantity</th>
                        <th>Current Price</th>
                        <th>Net Gain/Loss</th>
                        <th>Percentage Gain/Loss</th>
                        <th>Actions</th>
                    </tr>
                </thead>
                <tbody>
                    {portfolio.map((entry) => (
                        <tr key={entry.symbol}>
                            <td>{entry.symbol}</td>
                            <td>₹{entry.avgBuyPrice}</td>
                            <td>{entry.quantity}</td>
                            <td>₹{entry.currentPrice}</td>
                            <td className={entry.netGainLoss >= 0 ? 'gain-positive' : 'gain-negative'}>
                                {entry.netGainLoss >= 0 ? '+' : ''}₹{entry.netGainLoss.toFixed(2)}
                            </td>
                            <td className={entry.percentageGainLoss >= 0 ? 'gain-positive' : 'gain-negative'}>
                                {entry.percentageGainLoss >= 0 ? '+' : ''}{entry.percentageGainLoss.toFixed(2)}%
                            </td>
                            <td>
                                <button onClick={() => deleteFromPortfolio(entry.symbol)} className="delete-button">Delete</button>
                            </td>
                        </tr>
                    ))}
                </tbody>
            </table>
        </div>
    );
};

export default Portfolio;
