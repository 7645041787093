import React, { useEffect, useState, useContext } from 'react';
import axios from 'axios';
import { AuthContext } from '../../authContext/authContext';
import {
  Chart as ChartJS,
  CategoryScale,
  LinearScale,
  LineElement,
  PointElement,
  Title,
  Tooltip,
  Legend,
} from 'chart.js';
import { Line } from 'react-chartjs-2';
import './OrdersPage.css';
import moment from 'moment';

ChartJS.register(CategoryScale, LinearScale, PointElement, LineElement, Title, Tooltip, Legend);

const OrdersPage = () => {
  const [orders, setOrders] = useState([]);
  const [realizedGainsData, setRealizedGainsData] = useState([]);
  const [totalRealizedGains, setTotalRealizedGains] = useState(0);
  const { authData } = useContext(AuthContext);
  const pythonBackend_server = process.env.REACT_APP_PYTHON_BACKEND_URL_SERVER;
  const pythonEndpoint = `${pythonBackend_server}/api/user_orders`;

  const [startDate, setStartDate] = useState('');
  const [endDate, setEndDate] = useState('');
  const [filteredOrders, setFilteredOrders] = useState([]);
  const [showTodayOrders, setShowTodayOrders] = useState(true);
  const [error, setError] = useState(false);

  useEffect(() => {
    const fetchOrders = async () => {
      try {
        if (authData && authData.email) {
          const response = await axios.get(pythonEndpoint, {
            params: { email: authData.email }
          });
          if (response.data.success) {
            setOrders(response.data.orders);
            const sortedGainsData = response.data.realized_gains_data
              .map(entry => ({ ...entry, date: new Date(entry.date) }))
              .sort((a, b) => a.date - b.date);
            setRealizedGainsData(sortedGainsData);
            setTotalRealizedGains(response.data.total_realized_gains);

            const today = moment().startOf('day');
            const todaysOrders = response.data.orders.filter(order =>
              moment(order.date).isSame(today, 'day')
            );
            setFilteredOrders(todaysOrders);
          } else {
            console.error("Error fetching orders:", response.data.message);
          }
        }
      } catch (error) {
        console.error("Error fetching orders", error);
      }
    };

    fetchOrders();
  }, [authData, pythonEndpoint]);

  const chartData = {
    labels: realizedGainsData.map(entry => moment(entry.date).format('DD, MMM YYYY')),
    datasets: [
      {
        label: 'Realized Gains Over Time',
        data: realizedGainsData.map(entry => entry.gain),
        fill: false,
        borderColor: '#4CAF50',
        tension: 0.1,
        pointBackgroundColor: '#4CAF50',
      }
    ]
  };

  const chartOptions = {
    responsive: true,
    plugins: {
      legend: { position: 'top' },
      title: { display: true, text: 'Realized Gains Over Time' }
    },
    scales: {
      x: {
        title: { display: true, text: 'Date' },
        ticks: {
          maxTicksLimit: 10,
        }
      },
      y: {
        title: { display: true, text: 'Realized Gain (₹)' }
      }
    }
  };

  const handleSubmit = () => {
    if (!startDate || !endDate) {
      setError(true);
      return;
    }
    setError(false);
    setShowTodayOrders(false);

    const newFilteredOrders = orders.filter(order => {
      const orderDate = moment(order.date);
      return (!startDate || orderDate.isSameOrAfter(moment(startDate))) &&
        (!endDate || orderDate.isSameOrBefore(moment(endDate)));
    });
    setFilteredOrders(newFilteredOrders);
  };

  const handleReset = () => {
    setStartDate('');
    setEndDate('');
    setError(false);
    setShowTodayOrders(true);

    const today = moment().startOf('day');
    const todaysOrders = orders.filter(order =>
      moment(order.date).isSame(today, 'day')
    );
    setFilteredOrders(todaysOrders);
  };

  return (
    <div className="orders-page">
      <h2>Your Orders</h2>
      <div className="gains">
        <p>Total Realized Gains: <span>₹{totalRealizedGains.toFixed(2)}</span></p>
      </div>

      <div className="date-filter">
        <h3>Filter Orders by Date</h3>
        <label className={error ? 'error-highlight' : ''}>
          Start Date:
          <input type="date" value={startDate} onChange={(e) => setStartDate(e.target.value)} />
        </label>
        <label className={error ? 'error-highlight' : ''}>
          End Date:
          <input type="date" value={endDate} onChange={(e) => setEndDate(e.target.value)} />
        </label>
        {error && <p className="error-message">Please select both start and end dates to filter.</p>}
        <button onClick={handleSubmit}>Submit</button>
        <button onClick={handleReset}>Reset</button>
      </div>

      <div className="orders-list">
        {showTodayOrders && <h3>Today's Orders</h3>}
        {!showTodayOrders && <h3>Order History</h3>}
        {filteredOrders.length > 0 ? (
          <table className="orders-table">
            <thead>
              <tr>
                <th>Date</th>
                <th>Symbol</th>
                <th>Order Type</th>
                <th>Quantity</th>
                <th>Price</th>
              </tr>
            </thead>
            <tbody>
              {filteredOrders.map((order, index) => (
                <tr key={index}>
                  <td>{moment(order.date).format('DD, MMM YYYY')}</td>
                  <td>{order.symbol}</td>
                  <td>{order.order_type}</td>
                  <td>{order.qty}</td>
                  <td>₹{order.fill_price.toFixed(2)}</td>
                </tr>
              ))}
            </tbody>
          </table>
        ) : (
          <p>No orders found for this date range.</p>
        )}
      </div>
      <div className="chart-container">
        <Line data={chartData} options={chartOptions} />
      </div>
    </div>
  );
};

export default OrdersPage;
